// @ts-nocheck
import React, { useEffect, useState } from 'react';

// import lib
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryFetch } from '../../../hooks';
import { lingualOperations } from '../../../state/ducks/lingual';

// import states
import { sessionOperations } from '../../../state/ducks/session';
import NavigationItem from './NavigationItem/NavigationItem';


const NavigationItems = ({ changeLingualToBangla, changeLingualToEnglish, lingual, logout, session }) => {
  const authToken = localStorage.getItem('authToken');
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const paegListState = useQueryFetch('pageList');

  const [pagesData, setPagesData] = useState([]);

  // const fetchPages = async () => {
  //   const response = await fetch(
  //       'https://mangshobazar.com/api/page',
  //       {headers: {'Content-Type': 'application/json'}, method: "GET"}
  //   );
  //   setPagesData(await response.json());

  // };

  useEffect(() => {
    // fetchPages();

    // axios.get(`${config.baseURL}/api/page`).then((res) => {
  
    //   setPagesData(res.data.data);
    // });

    if (paegListState.isSuccess && lingual.isBangla) {
      const pageList =
        paegListState.data && paegListState.data.length > 0
          ? paegListState.data.map((prod) => {
              return {
                ...prod,
                ...prod.bn,
              };
            })
          : {};
      // @ts-ignore
      setPagesData(pageList);
    } else {
      const pageList =
        paegListState.data && paegListState.data.length > 0
          ? paegListState.data.map((prod) => {
              return {
                ...prod,
              };
            })
          : {};
      // @ts-ignore
      setPagesData(pageList);
    }
  }, [paegListState.isSuccess, lingual]);

  const handleLogout = async () => {
    await localStorage.removeItem('authToken');
    logout();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (url) => {
    setAnchorEl(null);
    history.push(url);
  };

  return (
    <ul
      className=''
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <NavigationItem link="/products" exact>
            {lingual.isBangla ? 'পণ্য': 'PRODUCTS'}
        </NavigationItem>

        <NavigationItem link="/offer" exact>
        {lingual.isBangla ? 'অফার': 'OFFER'}
            
        </NavigationItem>

        <NavigationItem link="/recipes" exact>
        {lingual.isBangla ? 'রেসিপি': 'RECIPES'}
            </NavigationItem> */}
  
      <span
        style={{
          fontSize: '1.9rem',
          color: '$color-code-1-black-3',
          fontWeight: 500,
          cursor: 'pointer',
        }}
      >
        <span
          style={{
            color: lingual.isEnglish ? '$product-card-button' : '',
          }}
          className={
            lingual.isEnglish
              ? 'navigationItem-lingualLink-active'
              : 'navigationItem-lingualLink'
          }
          onClick={() => changeLingualToEnglish()}
          
        >
          EN |
        </span>
        <span
          style={{
            color: lingual.isBangla ? '$product-card-button' : '',
          }}
          className={
            lingual.isBangla
              ? 'navigationItem-lingualLink-active'
              : 'navigationItem-lingualLink'
          }
          onClick={() => changeLingualToBangla()}
        >
          &nbsp;বাং
        </span>
      </span>

      {/* for pages */}

      {session.isAuthenticated ? (
        <>
          <NavigationItem link='/profile' exact>
            {lingual.isBangla ? 'প্রোফাইল' : 'PROFILE'}
          </NavigationItem>

          <NavigationItem link='/order' exact>
            {lingual.isBangla ? 'প্রোফাইল' : 'ORDERS'}
          </NavigationItem>

          <NavigationItem handleClickLink={handleLogout} isNotNavLink={true}>
            {lingual.isBangla ? 'লগ আঊট' : 'LOGOUT'}
          </NavigationItem>
        </>
      ) : (
        <NavigationItem link='/login' exact>
          {lingual.isBangla ? 'লগ ইন' : 'LOGIN'}
        </NavigationItem>
      )}

      {pagesData && pagesData.length > 0 && (
        <div
          style={{
            padding: '20px 0',
            borderTop: '1px solid $color-code-1-white-3',
          }}
        >
          {pagesData &&
            pagesData.length &&
            pagesData.map((page, idx) => (
              <span
                key={idx}
                onClick={() => handleClose(page.url)}
                style={{
                  fontSize: '1.8rem',
                  color: '$color-code-1-black-3',
                  fontWeight: 400,
                  cursor: 'pointer',
                  display: 'block',
                  marginBottom: '10px',
                  textTransform: 'uppercase',
                }}
              >
                {page.name}
              </span>
            ))}
        </div>
      )}
    </ul>
  );
};

const mapDispathToProps = {
  logout: sessionOperations.logout,
  login: sessionOperations.login,
  changeLingualToBangla: lingualOperations.changeLingualToBangla,
  changeLingualToEnglish: lingualOperations.changeLingualToEnglish,
};

const mapStateToProps = (state) => ({
  session: state.session,
  cartItems: state.cart,
  lingual: state.lingual,
});



// @ts-ignore
export default connect(mapStateToProps, mapDispathToProps)(NavigationItems);
