import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import Layout from './hoc/Layout';


// importing dynamic imports for code-splitting. 
const Billing = lazy(() => import('./pages/billing'));
const NotFound = lazy(() => import('./pages/notFound'));
const UserProfile = lazy(() => import('./components/UserProfile'));
const AuthLogin = lazy(() => import('./pages/authLogin'));
const AuthSignUp = lazy(() => import('./pages/authSignUp'));
const Category = lazy(() => import('./pages/category'));
const Order = lazy(() => import('./pages/dashbaord/components/Order'));
const OffersPage = lazy(() => import('./pages/offer'));
const OrderHistory = lazy(() => import('./pages/OrderHistory'));
const OrderStatus = lazy(() => import('./pages/OrderStatus'));
const Page = lazy(() => import('./pages/page'));
const PopularProducts = lazy(() => import('./pages/popularProducts'));
const ProductDetail = lazy(() => import('./pages/productDetail'));
const ProductDetailModal = lazy(() => import('./pages/ProductDetailModal'));
const RecipeDetails = lazy(() => import('./pages/recipeDetails'));
const Recipes = lazy(() => import('./pages/recipes'));
const SearchResults = lazy(() => import('./pages/searchResults'));
const StoreDirectory = lazy(() => import('./pages/storeDirectory'));
const Home = lazy(() => import('./pages/home'));



// import order status pages
// import {  } from "./pages/";



const RouteSwitch = () => {
  let location = useLocation();
  const renderCount = useRef(0);
  renderCount.current++;
  let background = renderCount.current > 2 && location.state?.['background'] ? location.state && location.state['background'] : '';

  return (
    <>
      <Suspense fallback={<div></div>}>

        <Switch location={background || location}>
          <Route exact path={'/'} component={Home} />
          <Route path={'/search'} component={SearchResults} />
          {/* <Route path={'/category'} component={Category} /> */}
          {/* category URL */}

          {/* <Route path={'/products'} component={MeatTypes} /> */}
          <Route path={'/offer'} component={OffersPage} />
          <Route path={'/popular'} component={PopularProducts} />
          <Route path={'/productDetail'} component={ProductDetail} />
          <Route path={'/recipeDetail'} component={RecipeDetails} />
          <Route path={'/billing'} component={Billing} />
          <Route path={'/signup'} component={AuthSignUp} />
          <Route path={'/login'} component={AuthLogin} />
          <Route path={'/notices/:id'} component={RecipeDetails} />
          <Route path={'/notices'} component={Recipes} />

          {/* full store directory */}
          <Route path={'/store-directory'} component={StoreDirectory} />


          <Route path={'/page'} component={Page} />
          {/* <Route path='/dashboard' component={Dashboard} /> */}
          <Route exact path='/order' component={Order} />
          {/* <Route path='/order/success' component={SuccessOrder} /> */}
          <Route exact path="/order-history" component={OrderHistory} />
          <Route exact path="/order/:status" component={OrderStatus} />
          {/* <Route
            exact
            path="/order/fail"
            component={OrderFail}
          /> */}
          <Route path='/profile' component={UserProfile} />

          <Route path={'/category/:categoryName/:subCategoryName'} component={Category} />
          <Route path={'/category/:categoryName'} component={Category} />

          <Route path={'/product/:categoryName/:productName'} component={ProductDetail} />

          {!background && (
            <Route
              path={'/product/:categoryName/:productName'}
              component={ProductDetailModal}
            />
          )}

          <Route path='*' component={NotFound} />

        </Switch>

        {background && (
          <Route
            path={'/product/:categoryName/:productName'}
            component={ProductDetailModal}
          />
        )}
      </Suspense>
    </>

  )
}


// import components

const App = () => {
  const [isShowConsent, showConsent] = useState(false);

  useEffect(() => {
    const getCookieContent = async () => {
      const contest = await localStorage.getItem('cookieConsent');
      if (contest) {
        showConsent(true);
        return <> </>;
      }
    };
    getCookieContent();
  }, []);




  let routes = (
    <RouteSwitch />
  );

  return (
    <BrowserRouter>
      <React.Fragment>
        <Layout>
          {routes}
        </Layout>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default App;

// TODO:
/*



# fix the image size in mobile and desktop of banner. (image is getting cropped out for somereason).
// # remove the search system from select of product card
# show alerts for the erros of signup and checkouts
// # remove auto selecting of azim poor / delivery options
# add dealer code
// # fix the search/icon/menu in small screen/desktop screen for toolbar/navbar
# save the cookie for content confirmation
# fix the delaying banner image issues
# logout button in the sibebar doesn't work on small screen
# close the navbar on going back
// # fix the registration responsive ness
// # related products in the mobile screen should two column
# when checking out if the user is not logged in,
 and places order then the user will get signin auto!,
 then if the account is created, show the alert of that
#

// admin site

# fix the product edit issue, if you update the product
# post => cooking time is not going
# order detail is crashing












*/








