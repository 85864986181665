//@ts-nocheck
import Popover from "antd/lib/popover";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import BackDrop from "../../components/UI/BackDrop";
import CustomPopOver from "../../components/CustomPopOver";

// import hooks
import { useQueryFetch } from "../../hooks";
import ProductCategoriesForMoreCollapse from "../../pages/home/ProductCategoriesForMoreCollapse";
import { lingualOperations } from "../../state/ducks/lingual";
import { sessionOperations } from "../../state/ducks/session";

// import lib
import CategoryItemForSideBar from "./CategoryItemForSideBar";
import MoreCategories from "./MoreCategories";

// import components
import Logo from "../../components/Logo";
const mutationObserver = (targetNode, handler) => {
  const config = { attributeFilter: ["class"] }; // filter the mutations you want to listen

  const callback = function (mutationsList) {
    for (let mutation of mutationsList) {
      handler(mutation.oldValue);
    }
  };

  const observer = new MutationObserver(callback);

  observer.observe(targetNode, config);
};

const SideBar = ({
  lingual,
  session,
  changeLingualToBangla,
  logout,
  login,
  changeLingualToEnglish,
}) => {
  const [isMoreCollapseOpen, setIsMoreCollapseOpen] = useState(false);
  const toggleMoreCollapseOpen = () =>
    setIsMoreCollapseOpen((isOpen) => !isOpen);

  const reff = useRef();
  const newArrivalState = useQueryFetch("newArrival");
  const flashState = useQueryFetch("flash");

  const hasClass = (elId, className) => {
    const sidebarEl = document.getElementById(elId);
    return sidebarEl?.getAttribute("class")?.split(" ").includes(className);
  };

  const myHandler = () => {
    const isSideBarHasActiveMobileS = hasClass("sidebar", "activeMobileS");
    if (isSideBarHasActiveMobileS) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    mutationObserver(reff.current, myHandler);
  }, []);

  const offerCount = useQueryFetch("offerListCount", {
    urlOptions: {
      params: {
        limitNumber: 1000000000,
      },
    },
  });

  const categoryListState = useQueryFetch(
    "categoryList",
    {
      urlOptions: {
        params: {
          isSubCategory: true,
        },
      },
    },
    "categorylist-2"
  );

  const [categoryListData, setCategoryListData] = useState([]);

  const [ShowPopOver, setShowPopOver] = useState(false);

  const handlePopOverShow = () => {
    setShowPopOver(true);
  };

  const handlePopOverClose = () => {
    setShowPopOver(false);
  };

  useEffect(() => {
    if (categoryListState.isSuccess && categoryListState.data) {
      setCategoryListData(categoryListState.data);
    }
  }, [categoryListState.isSuccess]);

  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("customerData");
    localStorage.removeItem("city");
    logout();
    history.push("/");
  };

  useEffect(() => {
    if (lingual.isBangla && categoryListState.isSuccess) {
      const banglaCategoryList =
        categoryListState.data && categoryListState.data.length > 0
          ? categoryListState.data.map((cat: any) => {
              return {
                ...cat,
                ...cat.bn,
                ...(cat.subCategory &&
                  cat.subCategory.length > 0 &&
                  cat.subCategory[0] &&
                  cat.subCategory[0]["name"] && {
                    subCategory: cat.subCategory.map((subCat) => {
                      return {
                        ...subCat,
                        ...subCat.bn,
                      };
                    }),
                  }),
              };
            })
          : [];
      // @ts-ignore
      setCategoryListData(banglaCategoryList);
    } else {
      const banglaCategoryList =
        categoryListState.data && categoryListState.data.length > 0
          ? categoryListState.data.map((cat: any) => {
              return {
                ...cat,
              };
            })
          : [];
      // @ts-ignore
      setCategoryListData(banglaCategoryList);
    }
  }, [lingual, categoryListState.isSuccess]);

  const content = <MoreCategories />;

  const closeSideBar = () => {
    // $('#sidebar').toggleClass('activeMobileS');
    // $('#content').toggleClass('activeMobileC');

    const sidebarEl = document.getElementById("sidebar");
    const contentEl = document.getElementById("content");

    sidebarEl?.classList.toggle("activeMobileS");
    contentEl?.classList.toggle("activeMobileC");
  };

  const [show, setShow] = useState(false);

  const handleBackdropClose = () => {
    setShow(false);
    // $('#sidebar').removeClass('activeMobileS');
    const sidebarEl = document.getElementById("sidebar");
    sidebarEl?.classList.remove("activeMobileS");
  };

  return (
    <>
      <BackDrop show={show} clicked={handleBackdropClose} />
      <div
        id="sidebar"
        // @ts-ignore
        ref={reff}
      >
        {/* <div className='sideBarItemsForMobile'>
        <div className='sideDrawer2__navLinks pt-3 pb-3'>
          <div className='d-flex'>
            <a
              onClick={(e) => {
                e.preventDefault();
                changeLingualToEnglish();
              }}
              href=''
              style={{
                color: lingual.isBangla ? '' : 'rgb(255 76 7)',
              }}
            >
              EN
            </a>{' '}
            |{' '}
            <a
              onClick={(e) => {
                e.preventDefault();
                changeLingualToBangla();
              }}
              style={{
                color: lingual.isBangla ? 'rgb(255 76 7)' : '',
              }}
              href=''
            >
              বাং
            </a>
          </div>
          {session.isAuthenticated ? (
            <>
              <Link to='/dashboard'>
                {lingual.isBangla ? 'ড্যাশবোর্ড' : 'Dashboard'}
              </Link>
              <Link onClick={() => handleLogout()} to='#'>
                {lingual.isBangla ? 'লগ আঊট' : 'Logout'}
              </Link>
            </>
          ) : (
            <>
              <Link to='/login'>{lingual.isBangla ? 'লগ ইন' : 'Login'}</Link>
              <Link to='/signup'>
                {lingual.isBangla ? 'রেজিস্টার' : 'Register'}
              </Link>
            </>
          )}
        </div>
      </div> */}
        <>
          <div className="sideDrawer2__logo showLogoOnlyOnMobile">
            <Logo
              closeBackdrop={handleBackdropClose}
              closeSidebar={closeSideBar}
            />
          </div>
          <div
            onClick={() => {
              handleBackdropClose();
              // return closeSideBar();
            }}
            className="sideDrawer2__navLinks"
          >
            <h4 className="f700" onClick={() => history.push("/offer")}>
              {lingual.isBangla ? "অফার" : "OFFER"}

              <span className="float-right offerCount">
                {(offerCount.isSuccess &&
                  (lingual.isBangla
                    ? offerCount.data.toLocaleString("bn")
                    : offerCount.data)) ||
                  0}
              </span>
            </h4>

            {/* {newArrivalState.isSuccess &&
              newArrivalState.data &&
              newArrivalState.data["id"] &&
              newArrivalState.data["url"] &&
              newArrivalState.data["name"] && (
                <h4
                  className="f700"
                  onClick={() => {
                    history.push({
                      pathname: `/category/${newArrivalState.data[
                        "url"
                      ].replace("/category/", "")}`,
                      state: {
                        catId: newArrivalState.data["id"],
                      },
                    });
                  }}
                >
                  {lingual.isBangla ? "নিউ এরাইভাল" : "New Arrival"}
                </h4>
              )}

            {flashState.isSuccess &&
              flashState.data &&
              flashState.data["id"] &&
              flashState.data["url"] &&
              flashState.data["name"] && (
                <h4
                  className="f700"
                  onClick={() => {
                    history.push({
                      pathname: `/category/${flashState.data["url"].replace(
                        "/category/",
                        ""
                      )}`,
                      state: {
                        catId: flashState.data["id"],
                      },
                    });
                  }}
                >
                  {lingual.isBangla ? "ফ্ল্যাশ বিক্রয়" : "Flash Sales"}
                </h4>
              )}

            {/* <h4 className="f700" onClick={() => history.push('/recipes')}>RECIPES</h4> */}

            {/* <h4 className="f700" onClick={() => history.push("/popular")}>
              {lingual.isBangla ? "জনপ্রিয় পণ্য" : "Popular Products"}
            </h4> */}

            {/* <div className='sideDrawer2__loginLinks'>
            <NavigationItemsMobile />
          </div> */}
          </div>

          {categoryListState.isSuccess &&
            categoryListState.data &&
            categoryListData.length > 0 && (
              <>
                {categoryListData.slice(0, 12).map((item: any, idx) => {
                  //@ts-ignore
                  return (
                    <CategoryItemForSideBar
                      key={idx}
                      closeBackdrop={handleBackdropClose}
                      closeSidebar={closeSideBar}
                      item={item}
                    />
                  );
                })}
              </>
            )}

          {/* <Popover
            className='h sideDrawer2__bottomLinks'
            placement="right" content={content}
          >
          </Popover> */}
          {/* <div className="h sideDrawer2__bottomLinks">
            <div
              style={{ cursor: "pointer", lineHeight: "2" }}
              className="sideDrawer2__categoryItem justify-content-between less"
            >
              <div className="d-flex align-items-center">
                <i
                  style={{ marginRight: "5px" }}
                  className="fa fa-th-large"
                  aria-hidden="true"
                ></i>
                <span>{lingual.isBangla ? " আরো" : " MORE"}</span>
              </div>

              <CustomPopOver show={ShowPopOver}>{content}</CustomPopOver>
            </div>
          </div> */}

          {/* for mobile device */}
          {/* <a
            onClick={toggleMoreCollapseOpen}
            style={{ cursor: "pointer", lineHeight: "2" }}
            className="sideDrawer2__categoryItem justify-content-between more"
          >
            <div className="d-flex align-items-center">
              <i
                style={{ marginRight: "5px" }}
                className="fa fa-ellipsis-v"
                aria-hidden="true"
              ></i>
              {lingual.isBangla ? " আরো" : " MORE"}
            </div>
            <span className="dropdownArrow">
              <i className="fa fa-plus"></i>
            </span>
          </a> */}

          {/* {isMoreCollapseOpen && (
            <div>
              <ProductCategoriesForMoreCollapse
                closeBack={handleBackdropClose}
                closeS={closeSideBar}
              />
            </div>
          )} */}

          {/* <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              closeSideBar();
              handleBackdropClose();
              return history.push("/store-directory");
            }}
            className="fullStoreDirectory"
          >
            <h4 className="f700">
              {lingual.isBangla
                ? "ফুল স্টোর ডিরেক্টরী"
                : "FULL STORE DIRECTORY"}
              <span className="float-right">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </span>
            </h4>
          </div> */}
        </>

        {/* {categoryListState.isSuccess &&
            // categoryListState.data &&
            categoryListData.length > 0 && (
              <>
                {categoryListData.map((item: any) => {
                  return (
                    <div
                      onClick={() =>
                        item.url &&
                        history.push(`/e/${item.id}`)
                      }
                      className='sideDrawer2__categoryItem'
                    >
                      <div className='sideDrawer2__categoryItem-cover'>
                        <img src={item.icon ? item.icon : item.cover} alt='' />
                      </div>
                      <h3>{item.name}</h3>
                    </div>
                  );
                })}
              </>
            )} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
  lingual: state.lingual,
});

const mapDispatchToProps = {
  logout: sessionOperations.logout,
  login: sessionOperations.login,
  changeLingualToBangla: lingualOperations.changeLingualToBangla,
  changeLingualToEnglish: lingualOperations.changeLingualToEnglish,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(SideBar);
