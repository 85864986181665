import * as types from './types';

export const toggleLingual = () => ({
	type: types.TOGGLE_LINGUAL
});

export const changeLingualToEnglish = () => ({
	type: types.CHANGE_LINGUAL_TO_ENGISH
});



export const changeLingualToBangla = () => ({
	type: types.CHANGE_LINGUAL_TO_BANGLA
});

