import React, {
  lazy,
  Suspense,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
// import lib
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BottomCartIcon from "../../components/BottomCartIcon";
import CartIcon from "../../components/Cart-icon/Cart-icon.component";
import ScrollTopArrow from "../../components/ScrollTopArrow";
// import hooks
import { useHandleFetch } from "../../hooks";
import { Header, SideBar } from "../../layout/Footer";
import HeaderMobile from "../../layout/Footer/HeaderMobile";
import { cartOperations, cartSelectors } from "../../state/ducks/cart";
import { lingualOperations } from "../../state/ducks/lingual";
// import states
import { sessionOperations } from "../../state/ducks/session";
// import utils
import {
  deleteCity,
  deleteCustomerData,
  getCity,
  getCustomerData,
  saveCity,
  saveCustomerData,
} from "../../utils";

const CartDrawer = lazy(() => import("../../components/CartDrawer"));

const Layout = ({
  session,
  logout,
  login,
  children,
  cartItems,
  addProductsToCart,
  totalPrice,
}) => {
  const [logoutState, handleLogoutFetch] = useHandleFetch({}, "logout");

  const [customerDetailState, handleCustomerDetailFetch] = useHandleFetch(
    {},
    "getCurrentCustomerData"
  );

  const [sideDrawer, setsideDrawer] = useState(false);

  const sideDrawerClosedHandler = () => setsideDrawer(false);
  const sideDrawerToggleHandler = () => setsideDrawer(!sideDrawer);

  const [sideDrawer2, setsideDrawer2] = useState(false);

  const sideDrawerClosedHandler2 = () => setsideDrawer2(false);
  const sideDrawerOpenHandler2 = () => setsideDrawer2(true);
  const sideDrawerToggleHandler2 = () => setsideDrawer2(!sideDrawer);

  const [cartDrawer, setcartDrawer] = useState(false);

  const cartDrawerClosedHandler = () => setcartDrawer(false);
  const cartDrawerOpendHandler = () => setcartDrawer(true);

  const [bottomcartDrawer, setbottomcartDrawer] = useState(false);

  const bottomcartDrawerClosedHandler = () => setbottomcartDrawer(false);
  const bottomcartDrawerOpendHandler = () => setbottomcartDrawer(true);

  useEffect(() => {
    const getCheckAndSetCustomerData = async () => {
      const customerData = await handleCustomerDetailFetch({});
      // @ts-ignore
      if (!Object.keys(customerData).length > 0) {
        logout();
        await deleteCity();
        await deleteCustomerData();
      } else {
        const cutomerData = await getCustomerData();

        if (!cutomerData) {
          await saveCustomerData(customerData);
        }

        const city = await getCity();
        if (!city) {
          await saveCity(customerData["city"]);
        }

        if (!session.isAuthenticated) {
          login();
        }
      }
    };
    getCheckAndSetCustomerData();
  }, [session]);

  const [getCart, handlegetCartFetch] = useHandleFetch([], "getCart");

  useEffect(() => {
    const getAndSetToCart = async () => {
      const getCartRes = await handlegetCartFetch({});
      // @ts-ignore
      if (getCartRes && getCartRes.length > 0) {
        // @ts-ignore
        const cartItems = getCartRes.map((cartItem) => {
          return {
            quantity: cartItem["quantity"],
            product: cartItem["product"],
            variation: cartItem["variation"],
            name: cartItem["name"],
            cover: cartItem["cover"],
            price: cartItem["price"],
            totalPrice: cartItem["totalPrice"],
          };
        });

        addProductsToCart && addProductsToCart(cartItems);
      }
    };

    if (session.isAuthenticated) {
      getAndSetToCart();
    }
  }, [session]);

  const [windowWidth, setWindowWidth] = useState(0);

  const getWindowWidth = () => {
    return Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
  };

  useLayoutEffect(() => {
    setWindowWidth(getWindowWidth());
  }, []);

  const onResize = () => {
    window.requestAnimationFrame(() => {
      setWindowWidth(getWindowWidth());
    });
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <>
      <BottomCartIcon
        totalPrice={totalPrice}
        cartItems={cartItems}
        cartDrawerOpendHandler={cartDrawerOpendHandler}
        sideDrawerOpenHandler2={sideDrawerOpenHandler2}
      />

      {/* 
      <CartIcon
        totalPrice={totalPrice}
        cartItems={cartItems}
        cartDrawerOpendHandler={cartDrawerOpendHandler}
      />
      <Toolbar
        // @ts-ignore
        drawerToggleClicked={sideDrawerToggleHandler}
        sideDrawerToggleHandler2={sideDrawerToggleHandler2}
        changeLingualToBangla={changeLingualToBangla}
        changeLingualToEnglish={changeLingualToEnglish}
        lingual={lingual}
      />
      <CartDrawer open={cartDrawer} handleClose={cartDrawerClosedHandler} />

      <SideDrawer
        handleLogout={handleLogout}
        session={session}
        open={sideDrawer}
        closed={sideDrawerClosedHandler}
      />

      <SideDrawer2
        lingual={lingual}
        open={sideDrawer2}
        closed={sideDrawerClosedHandler2}
      /> */}

      {windowWidth > 768 ? <ScrollTopArrow /> : null}

      <CartIcon
        totalPrice={totalPrice}
        cartItems={cartItems}
        cartDrawerOpendHandler={cartDrawerOpendHandler}
      />

      <Suspense fallback={<></>}>
        <CartDrawer open={cartDrawer} handleClose={cartDrawerClosedHandler} />
      </Suspense>

      <Header />

      <HeaderMobile />

      <div className="wrapper">
        <SideBar />
        <main id="content" className="m-0">
          {children} {/* Main Content */}
        </main>
        {/* <Footer /> */}
      </div>
    </>
  );
};

const mapDispathToProps = {
  logout: sessionOperations.logout,
  login: sessionOperations.login,
  changeLingualToBangla: lingualOperations.changeLingualToBangla,
  changeLingualToEnglish: lingualOperations.changeLingualToEnglish,
  addProductsToCart: cartOperations.addProductsToCart,
};

const mapStateToProps = (state) => ({
  session: state.session,
  cartItems: state.cart,
  lingual: state.lingual,
  totalPrice: cartSelectors.getTotalPriceOfCartItems(state.cart),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispathToProps)(Layout));
