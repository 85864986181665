import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import lib
import { useHistory } from 'react-router';
// import hooks
import { useQueryFetch } from '../../hooks';

const MoreCategories = ({lingual}) => {
    const categoryListState = useQueryFetch('categoryList',{
        urlOptions: {
            params: {
                limitNumber: 4844038430483483084
            }
        }
    });
    const [categoryListData, setCategoryListData] = useState([]);

    useEffect(() => {
        if (categoryListState.isSuccess && categoryListState.data) {
            setCategoryListData(categoryListState.data);
        }
    }, [categoryListState.isSuccess]);

    useEffect(() => {
        if (lingual.isBangla && categoryListState.isSuccess) {
            const banglaCategoryList =
                categoryListState.data && categoryListState.data.length > 0
                    ? categoryListState.data.map((cat: any) => {
                        return {
                            ...cat,
                            ...cat.bn,
                        };
                    })
                    : [];
            // @ts-ignore
            setCategoryListData(banglaCategoryList);
        } else {
            const banglaCategoryList =
                categoryListState.data && categoryListState.data.length > 0
                    ? categoryListState.data.map((cat: any) => {
                        return {
                            ...cat,
                        };
                    })
                    : [];
            // @ts-ignore
            setCategoryListData(banglaCategoryList);
        }
    }, [lingual, categoryListState.isSuccess]);

    const history = useHistory();
    return (
        <div className="moreCategoriesContainer">
            {categoryListState.isSuccess &&
                categoryListState.data &&
                categoryListData.length > 0 && categoryListData.slice(7).map((catItem: any) => {
                    return (
                        <div className='moreCategoriesContainer__category'>
                            <a
                                href="###"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={(e) =>{
                                    e.preventDefault();
                                    catItem.url &&
                                    history.push({
                                        pathname: `/category/${catItem.url.replace('/category/', '')}`,
                                        state: {
                                            catId: catItem.id,
                                        }
                                      })
                                    }
                                }
                        
                                className='storeDirectory'
                            >
                                {/* <div className='storeDirectory__storeDirectoryImage'>
                                    <img src={catItem.cover} alt='' />
                                </div> */}
                                <div className='storeDirectory__storeDirectoryTitle'>
                                    <h4
                                        style={{
                                            fontSize: '13px', color: '#091436', fontWeight: 500,
                                        }} 
                                        className="mt-3">
                                        {catItem.name}
                                    </h4>
                                </div>
                                {/* {catItem.subCategory && catItem.subCategory.length > 0 && (
                                    <div className="subCategories">
                                        {catItem.subCategory.map(sub => (
                                            <a className="subCategories__subCategory">{sub.name}</a>
                                        ))}
                                    </div>
                                )} */}
                            </a>
                        </div>
                    );
                })}
        </div>
    );
};


const mapStateToProps = (state) => ({
    lingual: state.lingual,
});

// @ts-ignore
export default connect(mapStateToProps, {})(MoreCategories);