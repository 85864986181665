import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import config from "../../config.json";

// import hooks
import { useQueryFetch } from "../../hooks";
import { urlToString } from "../../utils";

const Logo = (props) => {

    return (
        <div
            onClick={() => {
                props.closeBackdrop();
                // props.closeSidebar();

            }}
        >
            <Link to={'/'}>
                <div
                    className="toolbarLogo">
                    <img src={`${config.baseURL}/images/logo.png`} alt="" />
                </div>
            </Link>
        </div>
    );
};

export default withRouter(Logo);