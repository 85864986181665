// @ts-nocheck
import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItemMobile.module.css";

const NavigationItem = ({
  handleClickLink,
  link,
  exact,
  children,
  isNotNavLink = false,
}) => (
  <li className={classes.NavigationItem}>
    {isNotNavLink ? (
      <a href="##" onClick={handleClickLink}>
        {children}
      </a>
    ) : (
      <NavLink activeClassName={classes.active} to={link} exact={exact}>
        {children}
      </NavLink>
    )}
  </li>
);

export default NavigationItem;
