import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MobileMenuModal from "../../components/MobileMenuModal";
import NavigationItemsMobile from "../../components/Navigation/NavigationItemsMobile";
// import config
import config from "../../config.json";
import { lingualOperations } from "../../state/ducks/lingual";

const HeaderMobile = ({
  lingual,
  changeLingualToBangla,
  changeLingualToEnglish,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    history.push({
      pathname: "/search",
      search: `?search=${""}&query=${searchValue || ""}`,
    });
  };

  const onSearchBarChange = (e) => {
    e.preventDefault();

    setSearchValue(e.target.value);
    history.push({
      pathname: "/search",
      search: `?search=${""}&query=${e.target.value || ""}`,
    });
  };

  useEffect(() => {
    if (location.pathname !== "/search") {
      setSearchValue("");
    }
  }, [location.pathname]);

  const [open, setOpen] = useState(false);

  const ModalCloseHandler = () => {
    setOpen(false);
  };

  const ModalOpenHandler = () => {
    setOpen(true);
  };

  return (
    <div className="container-fluid topBar d-md-none">
      <div className="row d-flex align-items-center p-2">
        <div className="mtmenuicon d-flex justify-content-center align-items-center">
          {/* <i className="fas fa-search"></i> */}
          {/* <SearchBar lingual={"empty"} /> */}
          {/* <div className="">
                        <a href="">EN</a> | <a href="">বাং</a>
                    </div> */}
          {/* <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2 w-100" type="search" placeholder="Search" aria-label="Search" />
                    </form> */}
          <span id="sidebarCollapseMobile">
            <svg
              width="32"
              height="32"
              viewBox="0 0 15 15"
              className="bi bi-list"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </span>
        </div>

        <div className="mtsearch tbsearch">
          <form className="form-inline my-2 my-lg-0">
            <input
              style={{
                padding: ".9rem",
                fontSize: "1.8rem",
              }}
              className="form-control mr-sm-2 w-100"
              type="search"
              aria-label="Search"
              placeholder={
                lingual.isBangla
                  ? "পণ্য খোঁজ করতে চাইলে (যেমন- মোবাইল, শাকসবজি)"
                  : "Search for products (e.g mobile,fruits)"
              }
              value={searchValue}
              onChange={onSearchBarChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch();
                }
              }}
            />
          </form>
        </div>

        <div className="mtloginicon d-flex align-items-center justify-content-center">
          {/* <a onClick={(e) => {
                        e.preventDefault();
                        history.push('/')
                    }} href="#">
                        <img src={`${config.baseURL}/images/logo.png`} height="10px" alt="Brand" />
                    </a> */}
          <i
            className="fa fa-ellipsis-v fa-2x"
            aria-hidden="true"
            onClick={ModalOpenHandler}
          ></i>
        </div>
        <MobileMenuModal show={open} modalClosed={ModalCloseHandler}>
          <div className="mobileMenuContents" onClick={ModalCloseHandler}>
            <div className="mobileMenuContents__header">
              {/* <span style={{paddingRight: "3rem"}}>Jirabo</span> */}
              <img
                src={`${config.baseURL}/images/logo.png`}
                height="25px"
                alt="Brand"
              />
            </div>
            <div className="mobileMenuContents__items">
              <NavigationItemsMobile />
            </div>
          </div>
        </MobileMenuModal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lingual: state.lingual,
});

const mapDispatchToProps = {
  changeLingualToBangla: lingualOperations.changeLingualToBangla,
  changeLingualToEnglish: lingualOperations.changeLingualToEnglish,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(HeaderMobile);
