import React from "react";
import styled from "styled-components";
import {connect} from 'react-redux';
// import { createStructuredSelector } from "reselect";

import { ReactComponent as ShoppingIconSVG } from "../../assets/shopping-bag.svg";
// import { selectCartItemsCount } from "../../redux/cart/cart.selectotors";


const CartIcon = (props) =>  {
   
    const { cartDrawerOpendHandler, cartItems, totalPrice, lingual: {isBangla} } = props;

    return (
      <button type="button" onClick={cartDrawerOpendHandler}>
        <ShoppingIconContainer>
          <ShoppingIcon />
          {/* <ItemCount>{itemCount}</ItemCount> */}
          <ItemCount>{isBangla ?  cartItems.length.toLocaleString('bn') : cartItems.length}</ItemCount>
          <h4 className="f700"
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            ৳ {isBangla ? totalPrice.toLocaleString('bn') : totalPrice}
          </h4>
        </ShoppingIconContainer>
      </button>
    );
  }

const mapStateToProps = state => ({
  lingual: state.lingual
})

export default connect(mapStateToProps)(CartIcon);
// export default CartIcon;


const ShoppingIconContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  /* width: 6vw; */

  cursor: pointer;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  z-index: 21;
  background: #8cc460;
  opacity: 1;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const ItemCount = styled.span`
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  top: 30%;
  left: 45%;
  color: black;
`;

const ShoppingIcon = styled(ShoppingIconSVG)`
  width: 40px;
  height: 40px;
`;



