//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Index = ({ item, closeSidebar, closeBackdrop }) => {
  const dropRef1 = useRef(null);
  const dropRef2 = useRef(null);

  const handleClick = (element) => {
    var x = document.getElementsByClassName("dropdown-container");
    var dropdownxContent = element.current.nextElementSibling;

    //@ts-ignore
    Array.from(x).forEach(function (item) {
      //@ts-ignore
      if (item === dropdownxContent) {
        return;
      }
      if (item.style.display === "block") {
        item.style.display = "none";
      }
    });

    if (dropdownxContent.style.display === "block") {
      dropdownxContent.style.display = "none";
    } else {
      dropdownxContent.style.display = "block";
    }
  };

  useEffect(() => {}, [item.id]);

  const history = useHistory();
  const location = useLocation();

  const [categoryId, setCategoryId] = useState("");
  const [iscollapse, setIsCollapse] = useState(true);

  // setCategoryId(orderItem['id']);
  // handleCollapse(orderItem['id']);

  const handleCollapse = (id) => {
    if (categoryId === id) {
      setIsCollapse(false);
    } else {
      setIsCollapse(true);
    }
  };

  return (
    <div className="drawerItemsForDesktop">
      <a
        ref={dropRef1}
        onClick={() => handleClick(dropRef1)}
        className="dropdownBtn"
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            item.url &&
              history.push({
                pathname: `/category/${item.url.replace("/category/", "")}`,
                state: {
                  catId: item.id,
                },
              });
            closeSidebar();
            closeBackdrop();
          }}
          className="sideDrawer2__categoryItem justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div className="sideDrawer2__categoryItem-cover">
              <img src={item.icon ? item.icon : item.cover} alt="" />
            </div>
            <h3
              style={{
                ...(location.pathname &&
                  location.pathname === item.url && {
                    color: "#82e693",
                    fontWeight: 900,
                  }),
              }}
              className="m-0"
            >
              {item.name}{" "}
            </h3>
          </div>
          {item.subCategory && item.subCategory.length > 0 && (
            <span className="dropdownArrow ">
              <i className="fas fa-angle-down"></i>
            </span>
          )}
        </div>
      </a>
      <div className="dropdown-container">
        {item.subCategory &&
          item.subCategory.length > 0 &&
          item.subCategory.map((sub) => {
            return (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  sub.url &&
                    history.push({
                      pathname: `/category/${sub.url.replace(
                        "/category/",
                        ""
                      )}`,
                      state: {
                        catId: sub.id,
                      },
                    });
                  closeSidebar();
                  closeBackdrop();
                }}
                className="dropdownBtn"
                href="#"
              >
                <div className="sideDrawer2__categoryItem justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="sideDrawer2__categoryItem-cover">
                      <img src={sub.cover || sub.icon} alt="" />
                    </div>
                    <h3
                      style={{
                        ...(location.pathname &&
                          location.pathname === sub.url && {
                            color: "#82e693",
                            fontWeight: 900,
                          }),
                      }}
                      className="m-0"
                    >
                      {sub.name}{" "}
                    </h3>
                  </div>
                  {/* <span className="dropdownArrow "><i className="fas fa-angle-down"></i></span> */}
                </div>
              </a>
            );
          })}

        {/* <a ref={dropRef2} onClick={() => handleClick(dropRef2)} className="dropdownBtn" href="#">Hew Sayed</a> */}
        {/* <div className="dropdown-container">
                    {item.subCategory && item.subCategory.length > 0 && item.subCategory.map(sub => {
                        return <a href="">{sub.name}</a>
                    })}

                </div> */}
        {/* <a href="">Sayed</a> */}
      </div>
    </div>
  );
};

export default Index;
