import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
// import { createStructuredSelector } from "reselect";

import { ReactComponent as ShoppingIconSVG } from "../../assets/shopping-bag.svg";
// import { selectCartItemsCount } from "../../redux/cart/cart.selectotors";

import HamBurger from "../HamBurger";

// import hooks
import { useQueryFetch } from "../../hooks";
const BottomCartDrawer = ({
  cartDrawerOpendHandler,
  cartItems,
  totalPrice,
  sideDrawerOpenHandler2,
  lingual,
}) => {
  const history = useHistory();

  const gotoproduts = () => {
    history.push("/billing");
  };

  const phoneState = useQueryFetch("phone");

  return (
    <ShoppingIconContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 500,
        }}
      >
        {/* <span 
            onClick={sideDrawerOpenHandler2}
                style={{
                    display: 'flex',
                    color: '$color-code-1-text-1',
                    height: '100%',
                    lineHeight: '50px',
                    fontSize: 15,
                    width: '50px',
                    justifyContent:'center',
                    alignItems:'center'
                    
                }}
            >
                <i className='fa fa-bars'></i>
            </span> */}
        <span
          onClick={
            cartItems && cartItems.length > 0
              ? gotoproduts
              : sideDrawerOpenHandler2
          }
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            backgroundColor: "$product-card-button",
            height: "100%",
            lineHeight: "40px",
            color: "$color-code-1-text-1",
            fontWeight: 700,
            fontSize: 15,
            justifyContent: "center",
          }}
        >
          {cartItems && cartItems.length > 0 ? (
            lingual.isBangla ? (
              "চেকআউট"
            ) : (
              "Checkout"
            )
          ) : (
            <>
              {phoneState.isSuccess &&
              phoneState.data &&
              phoneState.data["text"] ? (
                <>
                  <a href={`tel:${phoneState.data["text"]}`}>
                    {lingual.isBangla
                      ? parseInt(phoneState.data["text"])
                          .toLocaleString("bn")
                          .split(",")
                          .join("")
                      : phoneState.data["text"]}
                  </a>
                </>
              ) : lingual.isBangla ? (
                "চেকআউট"
              ) : (
                "Checkout"
              )}
            </>
          )}
        </span>
        <span
          onClick={cartDrawerOpendHandler}
          style={{
            display: "flex",
            color: "$color-code-1-text-1",
            height: "100%",
            lineHeight: "50px",
            fontSize: 14,
            width: "50px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <i
            style={{
              marginBottom: "-10px",
              marginTop: "17px",
            }}
            className="fa fa-shopping-bag"
          ></i>
          <span
            style={{
              fontSize: "12px",
              fontWeight: 700,
              color: "$color-code-1-text-1",
              borderRadius: "50%",
            }}
          >
            {/* {totalPrice || 0} */}
            {lingual.isBangla ? totalPrice.toLocaleString("bn") : totalPrice}
          </span>
        </span>
      </div>
    </ShoppingIconContainer>
  );
};

const ShoppingIconContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  z-index: 21;
  background: #f5f5f5;
  display: flex;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const ItemCount = styled.span`
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  top: 30%;
  left: 45%;
  color: black;
`;

const ShoppingIcon = styled(ShoppingIconSVG)`
  width: 40px;
  height: 40px;
`;

// const mapStateToProps = createStructuredSelector({
//   itemCount: selectCartItemsCount,
// });

// export default connect(mapStateToProps)(CartIcon);
const mapStateToProps = (state) => ({
  lingual: state.lingual,
});

// @ts-ignore
export default connect(mapStateToProps, {})(BottomCartDrawer);
