import UserOutlined from '@ant-design/icons/UserOutlined';
//antd
import Menu from 'antd/lib/menu';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import config from '../../config.json';
import { useQueryFetch } from '../../hooks';
import { lingualOperations } from '../../state/ducks/lingual';
import { sessionOperations } from '../../state/ducks/session';



//antd const
const { SubMenu } = Menu;

const Header = ({
  login,
  logout,
  lingual,
  session,
  changeLingualToBangla,
  changeLingualToEnglish,
}) => {
  const history = useHistory();
  const location = useLocation();

  const phoneState = useQueryFetch("phone");

  useEffect(() => {
    const sidebarCollapsePC = document.getElementById("sidebarCollapsePC");
    const sidebarCollapseMobile = document.getElementById(
      "sidebarCollapseMobile"
    );

    const fn = () => {
      return (activeClass1, activeClass2) => {
        const sidebarEl = document.getElementById("sidebar");
        const contentEl = document.getElementById("content");

        sidebarEl?.classList.toggle(activeClass1);
        contentEl?.classList.toggle(activeClass2);
      };
    };

    sidebarCollapsePC?.addEventListener("click", () => {
      fn()("activePcS", "activePcC");
    });

    sidebarCollapseMobile?.addEventListener("click", () => {
      fn()("activeMobileS", "activeMobileC");
    });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("customerData");
    localStorage.removeItem("city");
    logout();
    history.push("/");
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = (url) => {
    setAnchorEl(null);
    history.push(url);
  };

  const [searchValue, setSearchValue] = useState("");

  const onSearchBarChange = (e) => {
    e.preventDefault();

    setSearchValue(e.target.value);
    history.push({
      pathname: "/search",
      search: `?search=${""}&query=${e.target.value || ""}`,
    });
  };

  useEffect(() => {
    if (location.pathname !== "/search") {
      setSearchValue("");
    }
  }, [location.pathname]);

  

  const handleSearch = () => {
    history.push({
      pathname: "/search",
      search: `?search=${""}&query=${searchValue || ""}`,
    });
  };

  const [customerDetailState, setCustomerDetailState] = useState(null);

  useEffect(() => {
    const getCheckAndSetCustomerData = async () => {
      // setIsLoading(true);
      const token = localStorage.getItem("authToken");
      // const customerData = await handleCustomerDetailFetch({});

      try {
        const customerData = jwt_decode(token);
        setCustomerDetailState(customerData);
        // @ts-ignore
        if (!Object.keys(customerData).length > 0) {
          // history.push('/signin');
          // await deleteCity();
          // props.logout();
        } else {
          // await saveCity(customerData['city']);
          // await saveCustomerData(customerData)
          if (!session.isAuthenticated) {
            // login();
          }
          // setIsLoading(false);
        }
      } catch (error) {
        localStorage.removeItem("authToken");
        // history.push('/login')
      }
    };
    getCheckAndSetCustomerData();
  }, [session]);
  // }, []);

 

  return (
    <>
      <div className="topBar topbar">
        <div className="tblogo d-flex align-items-center">
          <span id="sidebarCollapsePC">
            <svg
              width="32"
              height="32"
              viewBox="0 0 15 15"
              className="bi bi-list"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </span>

          <a
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
            className="ml-5"
            href="#"
          >
            <img
              src={`${config.baseURL}/images/logo.png`}
              height="40px"
              alt=""
            />
          </a>
        </div>
        <div className="tbsearch d-flex align-items-center">
          <form className="form-inline my-2 my-lg-0 w-100">
            <input
              style={{
                padding: ".9rem",
                fontSize: "1.5rem",
                fontWeight: "lighter",
              }}
              className="form-control mr-sm-2 w-100"
              type="search"
              aria-label="Search"
              placeholder={
                lingual.isBangla
                  ? "পণ্য খোঁজ করতে চাইলে (যেমন- মোবাইল, শাকসবজি)"
                  : "Search for products (e.g mobile,fruits)"
              }
              value={searchValue}
              onChange={onSearchBarChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch();
                }
              }}
            />
          </form>

          <span
            onClick={() => {
              handleSearch();
            }}
            className="float-right"
            style={{ marginLeft: "-30px", cursor: "pointer" }}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
        <div className="tbother">
          <div className="d-flex justify-content-center align-items-center">
            {phoneState.isSuccess &&
              phoneState.data &&
              phoneState.data["text"] && (
                <a
                  style={{
                    borderRight: "1px solid $color-code-1-black-3ad",
                    paddingRight: "3rem",
                  }}
                  href={`tel:${phoneState.data["text"]}`}
                  className="mr-2"
                >
                  <h3 className="m-0 f700">
                    <i className="fas fa-headset"></i>{" "}
                    {lingual.isBangla
                      ? parseInt(phoneState.data["text"]).toLocaleString('bn').split(',').join('')
                      : phoneState.data["text"]}
                  </h3>
                </a>
              )}

            <div className="d-flex ml-5 mr-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  changeLingualToEnglish();
                }}
                href=""
              >
                <span
                  className="m-0 f700 language"
                  style={{
                    color: lingual.isBangla ? "" : "#E81C37",
                  }}
                >
                  EN{" "}
                </span>
              </a>{" "}
              <span className="mx-2" style={{ marginTop: "2px" }}>
                |
              </span>{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  changeLingualToBangla();
                }}
                href=""
                style={{
                  color: lingual.isBangla ? "#E81C37" : "",
                }}
              >
                <span className="m-0 f700 language"> বাং</span>
              </a>
            </div>
          </div>
        </div>
        <div className="tsignbutton d-flex align-items-center justify-content-center">
          {session.isAuthenticated ? (
            <>
              {/* <Link to='/dashboard'>Dashboard</Link> */}
              {/* <Link onClick={() => handleLogout()} to='#'>
                {lingual.isBangla ? 'লগ আঊট' : 'Logout'}
              </Link> */}

              <div>
                <Menu
                  //  onClick={this.handleClick}
                  style={{
                    width: "100%",
                    display: "inherit",
                    alignItems: "inherit",
                    justifyContent: "inherit",
                    color: "$color-code-1-black-3",
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    height: "100%",
                    // padding: '16px 10px',
                    fontFamily: "'Open Sans', Hind Siliguri, sans-serif",
                    outline: "none",
                    background: "none",
                    borderBottom: "none",
                  }}
                  selectedKeys={["SubMenu"]}
                  mode="horizontal"
                >
                  <SubMenu
                    key="SubMenu"
                    icon={<UserOutlined />}
                    //@ts-ignore
                    title={
                      lingual.isBangla
                        ? `${
                        // @ts-ignore
                        customerDetailState && customerDetailState?.firstName
                        }`
                        : `${
                        // @ts-ignore
                        customerDetailState && customerDetailState?.firstName
                        }`
                    }
                  >
                    <Menu.Item
                      style={{
                        fontSize: "1.3rem",
                        borderBottom: "1px solid #ddd",
                      }}
                      onClick={() => {
                        // handleClose('https://www.Onno Bosro.com');
                        history.push("/profile");
                      }}
                    >
                      Profile
                    </Menu.Item>
                    <Menu.Item
                      style={{
                        fontSize: "1.3rem",
                        borderBottom: "1px solid #ddd",
                      }}
                      onClick={() => {
                        // handleClose('https://www.Onno Bosro.com');
                        history.push("/order");
                      }}
                    >
                      Orders
                    </Menu.Item>
                    <Menu.Item
                      style={{
                        fontSize: "1.3rem",
                        borderBottom: "1px solid #ddd",
                      }}
                      onClick={() => {
                        handleLogout();
                        // handleClose('/');
                      }}
                    >
                      Logout
                    </Menu.Item>
                  </SubMenu>
                  {/* <i
              style={{
                marginLeft: '5px',
              }}
              className='fa fa-chevron-down'
            ></i> */}
                </Menu>
              </div>
            </>
          ) : (
              <>
                <Link to="/login">
                  <h3 className="m-0 f700 text-white">
                    {lingual.isBangla ? "সাইন ইন" : "Sign In"}
                  </h3>
                </Link>
                {/* <Link to='/signup'>
                  {lingual.isBangla ? 'রেজিস্টার' : 'Register'}
                </Link> */}
              </>
            )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
  lingual: state.lingual,
});

const mapDispatchToProps = {
  logout: sessionOperations.logout,
  login: sessionOperations.login,
  changeLingualToBangla: lingualOperations.changeLingualToBangla,
  changeLingualToEnglish: lingualOperations.changeLingualToEnglish,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(Header);
